export default {
  interests: {
    title: 'Intérêts',
    relationship: 'Relation',
    onenightstand: "Aventure d'un soir",
    kissing: 'Embrasser',
    hugging: 'Câlins',
    massages: 'Massages',
    lingerie: 'Lingerie',
    sex: 'Sexe',
    safesex: 'Sexe protégé',
    publicsex: 'Sexe en public',
    oralsex: 'Sexe oral',
    analsex: 'Sexe anal',
    sextoys: 'Jouets sexuels',
    masturbating: 'Masturbation',
    exchangingpictures: 'Échange de photos',
    threesome: 'Plan à trois',
    bondage: 'Bondage',
    sadomasochism: 'Sadomasochisme',
    gym: 'Gym',
    sports: 'Sports',
    dancing: 'Danser',
    travelling: 'Voyager',
    adventure: 'Aventure',
    party: 'Faire la fête',
    tattoos: 'Tattoos',
    music: 'Musique',
    movies: 'Films',
    videogames: 'Jeux vidéo',
    politics: 'Politique',
    outdoors: 'Activités de plein air',
    gardening: 'Jardinage',
    hiking: 'Randonnée',
    biking: 'Vélo',
    reading: 'Lecture',
    animals: 'Animaux',
    beach: 'Plage',
    art: 'Art',
    photography: 'Photographie',
    theater: 'Théâtre',
    shopping: 'Shopping',
    fashion: 'Mode',
    astrology: 'Astrologie',
    cooking: 'Cuisine',
    foodie: 'Gastronomie',
    bbq: 'Barbecue',
    sushi: 'Sushi',
    vegetarian: 'Végétarien',
    wine: 'Vin',
    beer: 'Bière',
    tea: 'Thé',
    coffee: 'Café',
  },

  FICTIONAL_PROFILE_FETISH: 'Fétiche de profil fictif : {name}',
  REQUEST_AWAY_TIME: 'Temps de pause du chat',
  REQUEST_AWAY_TIME_SUB: 'Obtenir 2 minutes',
  REQUEST_AWAY_TIME_WHY:
    'Pourquoi avez-vous besoin de temps en dehors de ce chat ? Toutes les demandes sont signalées aux coachs.',
  REQUEST_AWAY_TIME_BTN: 'Obtenir 2 minutes',
  '{count} Chats available':
    '(1) chat disponible | ({count}) chats disponibles',
  fictional_text_warning:
    "L'utilisateur peut parler de profils fictifs. Veuillez contacter le maître.",
  chat_back_to: {
    index: 'Retour au panneau principal',
    messages: 'Retour à @:(chat_overview)',
    history: 'Retour à @:(message_history)',
    message_reports: 'Retour à @:(message_reports)',
    skiplog: 'retour à @:(skiplog)',
  },
  SKIP_MESSAGE_REASON_TITLE: 'Entrer la raison du saut',
  SKIP_REASON: 'Raison du saut',
  SKIP_REASON_HINT: 'Expliquez pourquoi vous devez sauter ce message',
  SKIP_MESSAGE_CANCEL: 'Retour à la conversation',
  SKIP_MESSAGE_CONTINUE: 'Sauter le message',
  'Report message': 'Signaler le message',
  REPORT_MESSAGE_BTN: 'Envoyer le rapport de message',
  REPORT_MESSAGE: 'Signaler le message',
  REPORT_NOTE_HINT: 'Expliquez ce qui ne va pas avec ce message',
  REPORT_REASON_LABEL: 'Raison',
  REPORT_NOTE_LABEL: 'Notes',
  chat_overview: 'Aperçu du chat',
  skiplog: 'Journal des sauts',
  message_reports: 'Rapports de messages',
  'Number of messages in conversation':
    'Nombre de messages dans la conversation',
  'Filter conversations': 'Filtrer les conversations',
  'No minimum': 'Aucun minimum',
  'No maximum': 'Aucun maximum',
  'All statuses': 'Tous les statuts',
  'Operator status': "Statut de l'opérateur",
  'Paid users': 'Utilisateurs payants',
  'Free users': 'Utilisateurs gratuits',
  'Member type': 'Type de membre',
  'Number of pokes after last message':
    'Nombre de pokes après le dernier message',
  'All users': 'Tous les utilisateurs',
  Minimum: 'Minimum',
  Maximum: 'Maximum',
  operator_status: {
    unread: 'Non lu',
    unanswered: 'Non répondu',
    ignored: 'Ignoré',
    answered: 'Répondu',
  },

  Length: 'Longueur',
  Message: 'Message',
  Answered: 'Répondu',
  SKIP_DATE: 'Sauté',
  SENT_DATE: 'Envoyé',
  'Filter messages': 'Filtrer les messages',
  'All message types': 'Tous les types de messages',
  'All operators': 'Tous les opérateurs',
  'Message type': 'Type de message',
  message_history: 'Historique des messages',
  'load many': 'charger plusieurs',
  SKIP_MESSAGE_WARNING_TITLE: 'Ne pas envoyer le message',
  SKIP_MESSAGE_WARNING:
    'Êtes-vous sûr de vouloir continuer sans envoyer votre message ?',
  SKIP_MESSAGE_SEND: 'Envoyer le message maintenant',
  SKIP_MESSAGE_DELETE: 'Supprimer le message',
  Paused: 'En pause',
  Permissions: 'Autorisations',
  operator_name: 'Nom',
  open_conversations: 'Conversations ouvertes',
  no_open_conversations:
    'Pas de conversations ouvertes / en attente dans la file',
  logged_in_operators: 'Opérateurs connectés',
  language: 'Langue',
  cnt_pokes: '# pokes',
  cnt_messages: '# messages',
  cnt_operators: '# opérateurs',

  oldest_message: 'Message le plus ancien',
  newest_message: 'Dernier message',
  QUEUE_STATS: "Statistiques de la file d'attente",
  SOMEONE_ELSE_ANSWERING: '{name} répond à ce chat.',
  SAVE: 'Enregistrer',
  SET_ALERT: 'Définir une alerte',
  AT_CUSTOMER_PROFILE: 'Sur le profil du client',
  STOP_POKES: 'Arrêter les pokes',
  IGNORE: 'Ignorer le chat',
  UNTIL_NEXT_MESSAGE: "Jusqu'au prochain message",
  IGNORE_CONVERSATION_WARNING:
    "L'utilisateur ne recevra pas de réponse ! Utilisez ceci uniquement en cas d'abus et personne ne doit répondre (par exemple mineurs)",
  stats: {
    total: 'Total tous opérateurs',
    message_type: 'Type de message',
    count_messages: '# messages',
    count_messages_answered: '# messages avec réponse',
    count_messages_answered_percentage: 'Pourcentage avec réponse',
    types: {
      starter: 'Début de conversation',
      reply: 'Première réponse au message du client (ou après photo)',
      extra_reply: 'Réponses supplémentaires',
      poke: 'Poke',
      extra_poke: 'Poke supplémentaire',
      starter_photo: 'Début de conversation (photo)',
      reply_photo: 'Première réponse au message du client (photo)',
      extra_reply_photo: 'Réponses supplémentaires (photo)',
      poke_photo: 'Poke (photo)',
      extra_poke_photo: 'Poke supplémentaire (photo)',
      starter_gift: 'Début de conversation (cadeau)',
      reply_gift: 'Première réponse au message du client (cadeau)',
      extra_reply_gift: 'Réponses supplémentaires (cadeau)',
      poke_gift: 'Poke (cadeau)',
      extra_poke_gift: 'Poke supplémentaire (cadeau)',
    },
  },

  Stats: 'Statistiques',
  OTHER_CHAT_COUNT: '1 chat | {count} chats',
  FREE_MEMBER: "Membre d'essai",
  PREMIUM_MEMBER: 'Membre premium',
  USER_CREDITS: '1 crédit restant | {count} crédits restants',
  LAST_MESSAGE: '1 message, {date} | {num_messages} messages, dernier {date}',
  MEMBER_SINCE: 'Membre depuis {date}',
  MESSAGE_COUNT: '1 message | {count} messages',
  PHOTOMESSAGE_COUNT: '1 photo | {count} photos',
  INVISIBLE_PHOTOMESSAGE_COUNT: '1 photo invisible | {count} photos invisibles',
  MESSAGE_SINCE: 'Premier message : {date}',
  today: "Aujourd'hui",
  yesterday: 'Hier',
  TFA_LOGIN:
    'Entrez les 6 chiffres de votre application 2FA (Google Authenticator ou Authy)',
  SEND_POKE: 'Envoyez un poke à {name} !',
  AGE_DISPLAY: '{age} ans',
  'Empty chat, send a message first.':
    'Chat vide, envoyez un message en premier.',
  'Load more messages': 'Charger plus de messages',
  loading_messages: 'Chargement des messages...',
  SEND_MESSAGE_PLACEHOLDER: 'Envoyer un message',
  SEND_BTN: 'Envoyer',
  LOCKED_BY: 'Message verrouillé par : {name}',
  CUSTOMER: 'CLIENT',
  PLAYER: 'JOUEUR',
  'Open chat queue': "Ouvrir la file d'attente de chat",
  'Open poke queue': "Ouvrir la file d'attente de poke",
  'Poke Queue': "File d'attente de poke",
  Logout: 'Déconnexion',
  'Waiting for new message': "En attente d'un nouveau message",
  'Message Queue': "File d'attente des messages",
  LOGIN_BLOCKED:
    'Trop de tentatives échouées, veuillez réessayer dans 10 minutes.',
  LOGIN_FAILED: 'Échec de la connexion, réessayez',
  male: 'Homme',
  female: 'Femme',
  personal: 'Personnel',
  birthday: 'Anniversaire',
  age: 'Âge',
  city: 'Ville',
  sports: 'Sport',
  job: 'Travail',
  likes: 'Aime',
  hobbies: 'Loisirs',
  children: 'Enfants',
  pets: 'Animaux',
  important: 'Important',
  piercings: 'Piercings',
  reason: 'Raison',
  other: 'Autre',
  smokes: 'Fume',
  tattoos: 'Tatouages',
  eye_color: 'Couleur des yeux',
  hair_color: 'Couleur des cheveux',
  reletionship_status: 'Statut de la relation',
  yes: 'Oui',
  no: 'Non',
  blue: 'Bleu',
  brown: 'Marron',
  green: 'Vert',
  gray: 'Gris',
  hazel: 'Noisette',
  bald: 'Chauve',
  black: 'Noir',
  blond: 'Blond',
  'dark blond': 'Blond foncé',
  'dark brown': 'Brun foncé',
  red: 'Rouge',
  divorced: 'Divorcé',
  'living together': 'Vie commune',
  married: 'Marié',
  single: 'Célibataire',
  unmarried: 'Non marié',
  widowed: 'Veuf',
  sometimes: 'Parfois',
  stopping: "Essaye d'arrêter",
  hetero: 'Hétéro',
  gay: 'Gay',
  lesbian: 'Lesbienne',
  bi: 'Bisexuel',
  length: 'Taille',
  weight: 'Poids',
};
