export default {
  interests: {
    title: 'Interesses',
    relationship: 'Relatie',
    onenightstand: 'One night stand',
    kissing: 'Zoenen',
    hugging: 'Knuffelen',
    massages: 'Massages',
    lingerie: 'Lingerie',
    sex: 'Seks',
    safesex: 'Veilige seks',
    publicsex: 'Openbare seks',
    oralsex: 'Orale seks',
    analsex: 'Anale seks',
    sextoys: 'Seks Speeltjes',
    masturbating: 'Masturberen',
    exchangingpictures: 'Fotos uitwisselen',
    threesome: 'Trio',
    gangbang: 'Gangbang',
    bondage: 'Bondage',
    sadomasochism: 'SM',
    gym: 'Sportschool',
    sports: 'Sporten',
    dancing: 'Dansen',
    travelling: 'Reizen',
    adventure: 'Avontuur',
    party: 'Feesten',
    tattoos: 'Tatoeages',
    music: 'Muziek',
    movies: 'Films',
    videogames: 'Videospellen',
    politics: 'Politiek',
    outdoors: 'Buiten zijn',
    gardening: 'Tuinieren',
    hiking: 'Wandelen',
    biking: 'Fietsen',
    reading: 'Lezen',
    animals: 'Dieren',
    beach: 'Strand',
    art: 'Kunst',
    photography: 'Fotografie',
    theater: 'Theater',
    shopping: 'Winkelen',
    fashion: 'Mode',
    astrology: 'Astrologie',
    cooking: 'Koken',
    foodie: 'Foodie',
    bbq: 'BBQ',
    sushi: 'Sushi',
    vegetarian: 'Vegetarisch',
    wine: 'Wijn',
    beer: 'Bier',
    tea: 'Thee',
    coffee: 'Koffie',
  },

  FICTIONAL_PROFILE_FETISH: 'Fictional profile fetish: {name}',
  '{count} Chats available':
    '(1) chat beschikbaar | ({count}) chats beschikbaar',
  fictional_text_warning:
    'Gebruiker heeft het mogelijk over fictieve profielen. Draag over aan een master.',
  chat_back_to: {
    index: 'Terug naar startscherm',
    messages: 'Terug naar @:(chat_overview)',
    history: 'Terug naar @:(message_history)',
    message_reports: 'Terug naar @:(message_reports)',
    skiplog: 'Terug naar @:(skiplog)',
  },
  SKIP_MESSAGE_REASON_TITLE: 'Reden voor skip',
  SKIP_REASON: 'Skip reden',
  SKIP_REASON_HINT: 'Leg uit waarom je dit bericht wilt skippen',
  SKIP_MESSAGE_CANCEL: 'Terug naar gesprek',
  SKIP_MESSAGE_CONTINUE: 'Skip gesprek',
  'Report message': 'Meld bericht',
  REPORT_MESSAGE_BTN: 'Verstuur melding',
  REPORT_MESSAGE: 'Meld bericht',
  REPORT_NOTE_HINT: 'Leg uit wat er mis is met dit bericht',
  REPORT_REASON_LABEL: 'Reden',
  REPORT_NOTE_LABEL: 'Opmerkingen',
  Language: 'Taal',
  'All languages': 'Alle talen',
  'Site owner': 'Site eigenaar',
  'All site owners': 'alle site eigenaren',
  chat_overview: 'Chat overzicht',
  skiplog: 'Skiplog',
  'Number of messages in conversation': 'Aantal berichten in gesprek',
  'Filter conversations': 'Filter gesprekken',
  'No minimum': 'Geen minimum',
  'No maximum': 'Geen maximum',
  'All statuses': 'alle statussen',
  'Operator status': 'Operator status',
  'Paid users': 'Betaalde leden',
  'Free users': 'Gratis leden',
  'Member type': 'Lid type',
  'All users': 'Alle leden',
  'Number of pokes after last message': 'Aantal pokes sinds laatste bericht',
  operator_status: {
    unread: 'Ongelezen',
    unanswered: 'Onbeantwoord',
    ignored: 'Genegeerd',
    answered: 'Beantwoord',
  },

  Minimum: 'Minimum',
  Maximum: 'Maximum',
  'Days since last customer message (in any conversation)':
    'Dagen sinds laatste bericht klant (in alle gesprekken)',
  'Days since last message in conversation':
    'Dagen sinds laatste bericht in gesprek',
  Length: 'Tekens',
  Message: 'Bericht',
  Answered: 'Beantwoord',
  SKIP_DATE: 'Skipped',
  SENT_DATE: 'Verstuurdatum',
  'Filter messages': 'Filter berichten',
  'All operators': 'Alle operators',
  'All message types': 'Alle types',
  'Message type': 'Bericht type',
  message_history: 'Verzonden berichten',
  message_reports: 'Geraporteerd',
  'load many': 'Laad veel berichten',
  SKIP_MESSAGE_WARNING_TITLE: 'Onverstuurd bericht',
  SKIP_MESSAGE_WARNING:
    'Weet je zeker dat je wilt doorgaan zonder je bericht te versturen?',
  SKIP_MESSAGE_SEND: 'Bericht alsnog versturen',
  SKIP_MESSAGE_DELETE: 'Bericht verwijderen ',
  Paused: 'Gepauzeeerd',
  Permissions: 'Type',
  operator_name: 'Naam',
  open_conversations: 'Open gesprekken',
  no_open_conversations: 'Geen open gesprekken / in wachtrij',

  logged_in_operators: 'Chattende operators',
  language: 'Taal',
  cnt_pokes: 'Aantal pokes',
  cnt_messages: 'Aantal berichten',
  cnt_operators: 'Aantal operators',
  Languages: 'Talen',

  oldest_message: 'Oudste bericht',
  newest_message: 'Nieuwste bericht',
  QUEUE_STATS: 'Wachtrij statistieken',
  SOMEONE_ELSE_ANSWERING: '{name} is deze chat reeds aan het beantwoorden.',
  SAVE: 'Opslaan',
  SET_ALERT: 'Alert instellen',
  AT_CUSTOMER_PROFILE: 'Bij klantprofiel',
  STOP_POKES: 'Stop pokes',
  IGNORE: 'Negeer chat',
  UNTIL_NEXT_MESSAGE: 'Tot volgende bericht',
  IGNORE_CONVERSATION_WARNING:
    'Klant krijgt géén antwoord. Gebruik  dit alleen als niemand dit bericht moet beantwoorden, bijvoorbeeld bij minderjarigen.',
  stats: {
    total: 'Alle operators',
    message_type: 'Berichttype',
    count_messages: 'Aantal berichten',
    count_messages_answered: 'Aantal berichten met antwoord van klant',
    count_messages_answered_percentage: 'Percentage met antwoord',
    types: {
      starter: 'Conversatie start',
      reply: 'Directe reactie op bericht of na foto',
      extra_reply: 'Vervolg reactie op bericht',
      poke: 'Poke',
      extra_poke: 'Vervolg poke',
      starter_photo: 'Conversatie start (foto)',
      reply_photo: 'Directe reactie op bericht (foto)',
      extra_reply_photo: 'Vervolg reactie op bericht (foto)',
      poke_photo: 'Poke (foto)',
      extra_poke_photo: 'Vervolg poke (foto)',
      starter_gift: 'Conversatie start (cadeautje)',
      reply_gift: 'Directe reactie op bericht (cadeautje)',
      extra_reply_gift: 'Vervolg reactie op bericht (cadeautje)',
      poke_gift: 'Poke (cadeautje)',
      extra_poke_gift: 'Vervolg poke (cadeautje)',
    },
  },
  Stats: 'Statistieken',
  OTHER_CHAT_COUNT: '1 gesprek | {count} gesprekken',
  FREE_MEMBER: 'Gratis account',
  PREMIUM_MEMBER: 'Betaald account',
  USER_CREDITS: 'heeft nog 1 credit | heeft nog {count} credits',
  LAST_MESSAGE: '1 bericht, {date} | {num_messages} berichten, laatste {date}',
  MEMBER_SINCE: 'Lid sinds {date}',
  MESSAGE_COUNT: '1 bericht | {count} berichten',
  PHOTOMESSAGE_COUNT: "1 foto | {count} foto's",
  INVISIBLE_PHOTOMESSAGE_COUNT:
    "1 onzichtbare foto | {count} onzichtbare foto's",
  MESSAGE_SINCE: 'Eerste bericht: {date}',
  today: 'Vandaag',
  yesterday: 'Gisteren',
  TFA_LOGIN:
    'Gebruik hier de code uit je 2FA app (bijv Google Authenticater of Authy)',
  SEND_POKE: 'Stuur {name} een poke!',
  AGE_DISPLAY: '{age} jaar',
  'Back to main panel': 'Hoofdscherm',
  'Empty chat, send a message first.': 'Lege chat, stuur een eerste bericht',
  'Load more messages': 'Laad oudere berichten',
  loading_messages: 'Berichten worden geladen...',
  SEND_MESSAGE_PLACEHOLDER: 'Verstuur bericht..',
  LOCKED_BY: 'Bericht wordt al beantwoord door: {name}',
  SEND_BTN: 'Verstuur',
  CUSTOMER: 'KLANT',
  PLAYER: 'PROFIEL',
  'Open chat queue': 'Open chat',
  'Open poke queue': 'Open pokes',
  'Poke Queue': 'Poke wachtrij',
  Logout: 'Uitloggen',
  'Waiting for new message': 'Wachten op nieuw bericht..',
  'Message Queue': 'Berichten wachtrij',
  LOGIN_BLOCKED: 'Te veel foute pogingen, account voor 10 min geblokkeerd.',
  LOGIN_FAILED: 'Login mislukt, probeer opnieuw',
  age: 'Leeftijd',
  name: 'Naam',
  city: 'Woonplaats',
  birthday: 'Verjaardag',
  family: 'Familie',
  friends: 'Vrienden',
  sex: 'Seks',
  food: 'Eten',
  alcohol: 'Alcohol',
  health: 'Gezondheid',
  appearance: 'Uiterlijk',
  length: 'Lengte',
  weight: 'Gewicht',
  personality: 'Karakter',
  house: 'Woning',
  photos: "Foto's",

  male: 'man',
  female: 'vrouw',
  personal: 'Over mij',
  sports: 'Sport',
  job: 'Werk',
  likes: 'Houdt van',
  hobbies: "Hobby's",
  children: 'Kinderen',
  pets: 'Huisdieren',
  important: 'Belangrijk',
  piercings: 'Piercings',
  reason: 'Reden',
  other: 'Overig',
  smokes: 'Rookt',
  tattoos: 'Tatoeages',
  eye_color: 'Ogen',
  hair_color: 'Haarkleur',
  reletionship_status: 'Relatie',
  yes: 'Ja',
  no: 'Nee',
  blue: 'Blauw',
  brown: 'Bruin',
  green: 'Groen',
  gray: 'Grijs',
  hazel: 'Hazel',
  bald: 'Kaal',
  black: 'Zwart',
  blond: 'Blond',
  'dark blond': 'Donkerblond',
  'dark brown': 'Donkerbruin',
  red: 'Rood',
  divorced: 'Gescheiden',
  'living together': 'Samenwonend',
  married: 'Getrouwd',
  single: 'Vrijgezel',
  unmarried: 'Ongetrouwd',
  widowed: 'Weduwe',
  sometimes: 'Soms',
  stopping: 'Aan het stoppen',
  hetero: 'Hetero',
  gay: 'Gay',
  lesbian: 'Lesbisch',
  bi: 'Bi-seksueel',
};
